import React, {useState} from "react";
import {Field} from "formik";
import InputError from "../../common/input-error/InputError";
import {withTranslation} from "../../../i18n";
import 'react-dates/initialize';
import {SingleDatePicker} from 'react-dates';
import Tooltip from "../../Tooltip/Tooltip";
import moment from 'moment';
import 'moment/locale/fr';
import BasicSelect from "../basic-select/BasicSelect";
import css from "./InputDate.module.scss";

const namespacesRequired = ['common'];

const getYearsArray = (minDate, maxDate) => {
    let beginYear = moment().year() - 115;
    let endYear = moment().year() + 115;

    if (minDate) {
        beginYear = moment(minDate).year();
    }

    if (maxDate) {
        endYear = moment(maxDate).year();
    }

    const res = [];
    for (let i = endYear; i >= beginYear; i --) {
        res.push(i);
    }

    return res;
};

const InputDate = (props) => {

    const [focused, setFocused] = useState(false);
    const [date, setDate] = useState(props.inputValue);

    const years = getYearsArray(props.inputMinDate, props.inputMaxDate);

    const renderMonthElement = ({month, onMonthSelect, onYearSelect}) => (
        <div style={{display: 'flex', justifyContent: 'center'}}>
                <BasicSelect
                    customClass={css.month_year_select}
                    options={moment.months().map((label, value) => {
                        return {value: value, label: label};
                    })}
                    value={month.month()}
                    onChange={(val) => onMonthSelect(month, val.value)}
                />
                <BasicSelect
                    customClass={css.month_year_select}
                    options={years.map((year) => {
                        return {value: year, label: year};
                    })}
                    value={month.year() || years[0]}
                    onChange={(val) => onYearSelect(month, val.value)}
                />
        </div>);

    moment.locale(props.i18n.language);


    return (

        <Field name={props.inputName}
               id={props.inputId}
        >
            {({field: {value}, form: {setFieldValue, setFieldTouched}}) => (
                <div className={`${css.input__wrapper} ${props.customClass ? props.customClass : ''}`}
                     ref={props.anchorRef}
                >
                    <label
                        className={`${css.label} ${props.showTooltip ? css.label_inline_block : ''}`}
                    >
                        {props.label} {props.required && "*"}
                    </label>
                    {props.showTooltip && (<div className={css.test}>
                        <Tooltip customClass={css.custom__tooltip}
                                 triggerComponent={<span
                                     className={css.tooltip_orange}
                                 >?</span>}
                                 trigger="hover"
                        >
                            {props.tooltipContent}
                        </Tooltip>
                    </div>)}

                    <div className={`${css.input__date__wrapper}
                    ${!props.hideErrors && props.errors && props.errors[props.inputName] && (!props.touched || props.touched[props.inputName])
                        ? css.border__error
                        : css.border__no_error} ${focused ? css.border__no_error__focused : ''}`}
                    >
                        <SingleDatePicker
                            placeholder={props.inputPlaceholder}
                            numberOfMonths={1}
                            onDateChange={(date) => {
                                setFieldValue(props.inputName, date);
                                setDate(date);
                            }}
                            displayFormat={props.inputDateFormat || 'DD/MM/YYYY'}
                            onFocusChange={({focused}) => {
                                setFocused(focused);
                                if (!focused) {
                                    setFieldTouched(props.inputName, true);
                                    props.hideErrors && props.makeErrorsVisible && props.makeErrorsVisible();
                                }


                            }}
                            focused={focused}
                            initialVisibleMonth={() => date || props.inputInitialDate || moment()}
                            isOutsideRange={(date) => {
                                return false;
                            }}
                            isDayBlocked={(date) => {
                                const isBefore = !props.inputMaxDate || date.isBefore(props.inputMaxDate);
                                const isAfter = !props.inputMinDate || date.isAfter(props.inputMinDate);
                                return !(isBefore && isAfter);
                            }}
                            renderMonthElement={props.selectableMonthYear ? renderMonthElement : null}
                            date={value ? moment(value) : null}
                            disabled={props.disabled}
                            {...(props.datePickerProps ? props.datePickerProps : {})}
                        />
                        <button type="button"
                                className={css.input__date__icon}
                                onClick={() => setFocused(true)}
                        >
                            <img src={'/static/images/pictos/icon-calendar.svg'}/></button>

                    </div>

                    {!props.hideErrors && <InputError name={props.inputName}/>}
                </div>)}
        </Field>);
};

export default withTranslation(namespacesRequired)(InputDate);
