import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from "../../../i18n";
import InputError from "../../common/input-error/InputError";
import css from './Input.module.scss';
import Tooltip from "@/shared/Tooltip/Tooltip";


const namespacesRequired = ['common'];

const Input = ({
                   customClass,
                   inputId,
                   inputLabel,
                   inputType,
                   inputName,
                   inputPlaceholder,
                   errors,
                   touched,
                   hideErrors,
                   value,
                   onChange,
                   maxLength,
                   onBlur,
                   makeErrorsVisible,
                   disabled,
                   autocomplete,
                   tooltipText,
                   required,
                   ...props
               }) => {




    return (
        <div className={`${css.input__wrapper}  ${customClass || ""}`}>
            <label htmlFor={inputId}
                   className={css.label}
            >
                {inputLabel} {required && "*"}
                {tooltipText && <Tooltip ><p className={css.tooltip}>{tooltipText}</p></Tooltip>}
            </label>

            <input type={inputType}
                   name={inputName}
                   id={inputId}
                   placeholder={inputPlaceholder}
                   className={`${css.input} ${!hideErrors && errors[inputName] && (!touched || touched[inputName]) && css.input__error} ${disabled ? css.disabled_input : ''}`}
                   value={value ?? ''}
                   onChange={onChange}
                   maxLength={maxLength}
                   onBlur={onBlur}
                   onFocus={() => makeErrorsVisible && makeErrorsVisible()}
                   disabled={disabled}
                   autoComplete={autocomplete}
                   ref={props.anchorRef}
            />

            {!hideErrors && (
                <InputError name={inputName}
                />)}

        </div>
    );
};


Input.propTypes = {
    inputId: PropTypes.string.isRequired,
    inputLabel: PropTypes.string,
    inputName: PropTypes.string.isRequired,
    inputType: PropTypes.string,
    inputPlaceholder: PropTypes.string,
    required : PropTypes.bool
};

Input.defaultProps = {
    inputId: 'addAnId',
    inputLabel: 'Input desc :',
    inputName: 'addName',
    inputType: 'text',
    inputPlaceholder: 'Write Something'
};

export default withTranslation(namespacesRequired)(Input);

