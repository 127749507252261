import React, {useContext, useEffect} from 'react';
import {withTranslation} from '../../i18n';
import Navlink from "../navbar/navlink/Navlink";
import LinksProvider from "../../services/http/LinksProvider";
import Link from 'next/link';
import css from './MobileNavbar.module.scss';
import UserContext from "../../context/user/UserContext";

const namespacesRequired = ['common'];

const NavbarMobile = ({t, principal, isMenuOpen, triggerSidebar, honeymoonPageLink}) => {
  const userContext = useContext(UserContext);
  const {getActiveList, activeList, isFetching} = userContext;

  return (
    <div className={`${css.sidebar} ${isMenuOpen === true ? css.open : ""} ${isMenuOpen === false
      ? css.close
      : ""}`}
    >
      <section className={css.nav__group}>
        <Navlink url={LinksProvider.get(LinksProvider.ROUTES.HOW_IT_WORKS)}
                 name={t('common:navbar.ccm')}
        />
        <Navlink url={LinksProvider.get(LinksProvider.ROUTES.PARTNERS)}
                 name={t('common:navbar.partners')}
        />
        <Navlink url={LinksProvider.get(LinksProvider.ROUTES.PRODUCT.SEARCH_NO_PARAMS)}
                 name={t('common:navbar.gifts')}
        />
        <Navlink url={LinksProvider.get(LinksProvider.ROUTES.PUBLICATION.ALL_NO_PARAMS)}
                 name={t('common:navbar.threads')}
        />
        <Navlink url={LinksProvider.ROUTES.AVDN.PAGE}
                 name={t('common:navbar.honeymoon')}
        />
        <a href={!activeList
          ? LinksProvider.get(LinksProvider.ROUTES.LIST.CREATE)
          : LinksProvider.get(LinksProvider.ROUTES.LIST.LIST, {reference: activeList && activeList.reference})}
          className={css.btn__primary}>
            {(!activeList
              ? t('common:navbar.create')
              : t('common:consult-list'))}
          </a>
      </section>

    </div>
  );
};

export default withTranslation(namespacesRequired)(NavbarMobile);
