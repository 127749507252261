import React, {useState} from 'react';
import {withTranslation} from "../../../i18n";
import moment from "moment";
import {useToasts} from 'react-toast-notifications';
import UserService from "@/services/domain/UserService";
import Input from "@/shared/form/input/Input";
import InputDate from "@/shared/form/inputDate/InputDate";
import Button from "@/shared/button/simpleButton/Button";
import CustomForm from "@/shared/form/custom-form/CustomForm";
import css from './FooterNewsletter.module.scss';


const namespacesRequired = ['footer'];

const FooterNewsletter = ({t}) => {

    const {addToast} = useToasts();
    const [loading, setLoading] = useState(false);

    const getInitialValues = () => {
        return {
            wedding_date: '',
            email: '',
        };
    };


    const onSubmit = (values) => {
        const valueToUpdate = {
            wedding_date: values.wedding_date,
            email: values.email,
            opt_in_2: true,
            opt_in_1: true
        };

        setLoading(true);

        UserService.updateOptins(valueToUpdate)
            .then((user) => {
                addToast(t('footer:newsletter-confirm-message'), {
        appearance: 'success', autoDismiss: true
                });
            })
            .catch(error => {
                if (error.data && error.data.errors && error.data.errors[0] && error.data.errors[0].code === "E_INVALID_EMAIL") {
                    addToast(t('footer:newsletter-error-message'), {
            appearance: 'error', autoDismiss: true
                    });
                } else {
                    addToast(t('footer:newsletter-error-message-default'), {
            appearance: 'error', autoDismiss: true
                    });
                }

            })
            .finally(() => setLoading(false));


    };


    const renderForm = ({handleSubmit, handleChange, values, errors, touched}) => {

        return (

            <form onSubmit={handleSubmit}
                  noValidate
            >

                <div className={css.newsletter_form}>

                    <Input
                        customClass={css.email}
                        inputId="email"
                        inputLabel={''}
                        inputName="email"
                        inputType="email"
                        inputPlaceholder={t('footer:newsletter-placeholder')}
                        errors={errors}
                        touched={touched}
                        value={values['email']}
                        onChange={handleChange}
                        hideErrors={true}


                    />
                    <div className={css.mobile}>

                        <div className={css.date}>
                            <InputDate
                                inputName="wedding_date"
                                inputId="wedding_date"
                                errors={errors}
                                value={values['wedding_date']}
                                inputMinDate={moment()}
                                inputPlaceholder={t('footer:newsletter-date-placeholder')}
                                hideErrors={true}

                            />
                        </div>


                        <Button customClass={css.button}
                                loading={loading}
                                type="submit"
                                disabled={values['wedding_date'] === '' || values['wedding_date'] === null || values['email'] === ''}
                        >
                            <span>{t('footer:validate')}</span>

                        </Button>

                    </div>
                </div>


            </form>
        );
    };


    return (
        <div className={css.newsletter}>
            <div className={css.newsletter_content}>
                <h2>{t('footer:subscribe-title')}</h2>
                <p dangerouslySetInnerHTML={{__html: t('footer:subscribe-desc')}}/>
            </div>

            <CustomForm
                initialValues={getInitialValues()}
                onSubmit={onSubmit}
                render={renderForm}
            />


        </div>
    );
};

export default withTranslation(namespacesRequired)(FooterNewsletter);
