import React, {useEffect, useState} from 'react';
import DomService from '../../services/utils/DomService';
import FooterNewsletter from './footerNewsletter/FooterNewsletter';
import FooterSitemap from "./footerSitemap/FooterSitemap";
import css from './Footer.module.scss';

export default function Footer() {

  const [footerRef] = useState(React.createRef());

  function setFooterProperties(breakPoint, footerHeight) {
    const diff = window.scrollY - breakPoint;
    const translate = (footerHeight - diff) * 0.3;
    const ratio = diff / footerHeight;
    footerRef.current.style.opacity = ratio;
    footerRef.current.style.filter = `alpha(opacity=${ratio * 100})`;
    footerRef.current.style.bottom = `${-translate}px`;
  }

  const handlePageScroll = (e) => {
    if (!footerRef.current) {
      return;
    }
    const footerHeight = footerRef.current.offsetHeight;
    const breakPoint = DomService.documentHeight() - window.innerHeight - footerHeight;
    if (window.scrollY > breakPoint) {
      setFooterProperties(breakPoint, footerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handlePageScroll);
    const footerHeight = footerRef.current.offsetHeight;
    const breakPoint = DomService.documentHeight() - window.innerHeight - footerHeight;
    setFooterProperties(breakPoint, footerHeight);
    return () => window.removeEventListener('scroll', handlePageScroll);
  });

  return (
    <footer ref={footerRef}
            id="footer"
            className={css.footer}
    >
      <FooterNewsletter/>
      <FooterSitemap/>
    </footer>);
}
